exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-teamlife-index-tsx": () => import("./../../../src/pages/teamlife/index.tsx" /* webpackChunkName: "component---src-pages-teamlife-index-tsx" */),
  "component---src-pages-technologies-index-tsx": () => import("./../../../src/pages/technologies/index.tsx" /* webpackChunkName: "component---src-pages-technologies-index-tsx" */),
  "component---src-pages-terms-conditions-index-tsx": () => import("./../../../src/pages/terms-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-index-tsx" */)
}

